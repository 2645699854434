@import "./theme.css";
@import "./header.css";
@import "./footer.css";
@import "./utility.css";

.page-title {
  font-size: 1.875rem;
  font-weight: 600;
  padding: 10px 0px;
  margin-bottom: 20px;
  text-align: center;
}

.page-section-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin-top: 25px;
  margin-bottom: 5px;
}

.description {
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 1.25;
  margin: 0px;
  padding: 4px 0px;
  word-break: break-word;
}

.description a {
  color: var(--light) !important;
  text-decoration: none !important;
}

.description a:hover {
  color: var(--primary) !important;
}

#home,
#features,
#pricing,
#faq,
#socials {
  padding-top: 45px;
  padding-bottom: 45px;
  word-break: break-word;
}

#home .page-title,
#features .page-title,
#pricing .page-title,
#faq .page-title,
#socials .page-title {
  font-size: 1.625rem;
  margin-bottom: 20px;
  text-align: left;
}

#home {
  padding: 100px 15px 100px;
  position: relative;
  overflow: hidden;
}

#home .title {
  color: var(--primary);
  font-size: 2.25rem;
  font-weight: 500;
  line-height: 1.5;
  padding: 15px 0px;
  margin: 0px;
}

#home .info {
  color: var(--secondary);
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5;
  padding: 15px 0px;
  margin: 0px;
  max-width: 375px;
}

#home .btn-goto-purchase {
  background-color: transparent !important;
  border: 2px solid var(--secondary);
  border-radius: 10px;
  color: var(--primary) !important;
  font-size: 1.125rem;
  font-weight: 600;
  padding: 10px 45px;
  margin: 20px 0px;
  text-align: center;
}

#home img {
  background: transparent;
}

#home .img-generate {
  position: absolute;
  top: 100%;
  left: 40%;
  max-width: 30%;
  transform: translate(-90%, -55%) rotate(-20deg);
  filter: drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.356907));
}

#home .img-proxylist {
  position: absolute;
  top: 100%;
  left: 50%;
  max-width: 50%;
  transform: translate(-30%, -50%) rotate(-20deg);
  filter: drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.356907));
  zoom: 0.3;
}

#home .img-datacenter {
  position: absolute;
  top: 100%;
  left: 90%;
  max-width: 30%;
  transform: translate(-95%, -50%) rotate(-20deg);
  filter: drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.356907));
}

#home .img-usage {
  position: absolute;
  top: 100%;
  left: 40%;
  max-width: 25%;
  transform: translate(-60%, -30%) rotate(-20deg);
  filter: drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.356907));
}

#home .img-control {
  position: absolute;
  top: 100%;
  left: 80%;
  max-width: 30%;
  transform: translate(-60%, 0%) rotate(-20deg);
  filter: drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.356907));
}

#home .img-account {
  position: absolute;
  top: 100%;
  left: 45%;
  max-width: 35%;
  transform: translate(-55%, 70%) rotate(-20deg);
  filter: drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.356907));
}

#home img:hover {
  margin: -2px 0px;
  transition: all 0.25s linear;
}

.feature-card {
  padding: 15px 30px;
}

.features-container .main-card {
  background-color: var(--secondary);
  background-image: url("../img/Feature_BG.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 135% 135%;
  padding: 45px 15px 30px;
}

.feature-card .feature-img {
  background-color: var(--darker);
  border-radius: 5px;
  margin-bottom: 8px;
  padding: 5px;
  width: 50px;
}

.feature-card .feature-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin-left: 8px;
  padding: 0px;
}

.feature-card .feature-description {
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 1px;
  padding: 15px 5px;
  word-break: break-word;
}

#pricing {
  position: relative;
}

#pricing.Pricing_BG::after {
  content: "";
  background-image: url("../img/Pricing_BG.png");
  background-size: 100% 50%;
  background-repeat: no-repeat;
  width: 99vw;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -25%);
  z-index: -1;
}

.plan-section-group {
  background-color: var(--dark);
  border-radius: 10px;
  padding: 8px 10px;
}

.plan-section-group .btn-plan-selection {
  background-color: var(--dark) !important;
  border: none !important;
  border-radius: 5px !important;
  font-weight: 600;
}

.plan-section-group .btn-plan-selection.active {
  background-color: var(--darker) !important;
  border: none !important;
  color: var(--secondary) !important;
}

.category-pills .nav-item .nav-link {
  border: 2px solid transparent;
  color: var(--primary) !important;
  font-size: 18px;
  font-weight: 600;
  /* margin: 0px 2px; */
  padding: 8px 16px !important;
}

.category-pills .nav-item .nav-link.active {
  background-color: transparent !important;
  border: 2px solid var(--secondary);
  border-radius: 10px;
}

.card.plan-card {
  border: 1px solid var(--dark);
  background-color: var(--dark);
  border-radius: 10px;
  padding: 30px 30px;
}

.plan-card .card-header {
  background-color: var(--dark);
  border: none;
  border-radius: 10px;
  position: relative;
  padding: 15px 15px;
}

.plan-card .plan-card-title {
  display: inline-block;
  font-size: 1.5rem;
  font-weight: 600;
}

.plan-card .plan-card-type {
  color: var(--secondary);
  font-size: 1.125rem;
  font-weight: 600;
}

.plan-card .plan-card-price {
  color: var(--secondary);
  font-size: 2.25rem;
  font-weight: 700;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0px, -50%);
}

.plan-card .card-body {
  border-radius: 10px;
  padding: 15px 15px;
}

.plan-card .plan-card-features {
  list-style-type: none;
  margin: 0px;
  padding: 0px 15px;
}

.plan-card .plan-card-features li {
  color: var(--gray);
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25;
  letter-spacing: 1px;
  padding: 10px 0px;
  text-indent: -15px;
}

.plan-card .plan-card-features li span.important {
  color: var(--secondary) !important;
}

.plan-card .plan-card-features > li:before {
  color: var(--secondary);
  content: "—";
  font-size: 1rem;
  font-weight: 600;
  text-indent: -15px;
  margin-right: 5px;
}

.plan-card .plan-card-update-subPlan .btn-minus,
.plan-card .plan-card-update-subPlan .btn-plus {
  background: transparent;
  border: 0px;
  display: inline-block;
  font-size: 20px;
  margin: 10px 16px;
  padding: 0px;
}

.plan-card .plan-card-update-subPlan input.input-subplan {
  background: transparent;
  border: 0px;
  color: var(--primary);
  display: inline-block;
  font-size: 20px;
  font-weight: 600;
  min-width: 100px;
  margin-top: 0px;
  padding: 5px 0px 5px;
  text-align: center;
}

.plan-card .btn-plan-purchase {
  background: transparent !important;
  border-radius: 5px;
  border: 2px solid var(--secondary) !important;
  color: var(--primary) !important;
  padding: 8px 12px !important;
  font-weight: 500;
}

img.plan-card-datacenter-img {
  max-width: 33%;
  padding: 12px 30px;
  vertical-align: middle;
}

.faq-card.card,
.faq-card .card-header,
.faq-card .card-body {
  background: var(--darker);
  border-radius: 10px;
  width: 100%;
}

.faq-card.card {
  border: 2px solid var(--slategrey);
}

.faq-card .card-header {
  border: none;
  color: var(--primary);
  display: flex;
  align-items: center;
  font-size: 1.125rem;
  font-weight: 600;
  padding: 0px 45px 0px 30px;
  position: relative;
  min-height: 100px;
}

.faq-card .card-header.open {
  color: var(--secondary);
}

.faq-card .card-header:after {
  color: var(--primary);
  content: "\f078";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translate(0px, -50%);
  transition: 0.5s;
}

.faq-card .card-header.open:after {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.faq-card .card-body {
  font-size: 1rem;
  letter-spacing: 1px;
  min-height: 150px;
  padding: 10px 30px;
}

.faq-card .card-body p {
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 1.25;
  margin: 0px;
  padding: 5px 0px;
}

.faq-card .card-body p a {
  color: var(--color-info) !important;
  text-decoration: none !important;
  font-weight: 600;
}

.socials-container {
  background-color: var(--secondary);
  border-radius: 10px;
}

.socials-container {
  padding: 60px 30px;
}

.socials-container .message {
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0px;
}

.socials-container .btn-socials {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
  border-radius: 10px;
  color: var(--darker) !important;
  font-size: 1.125rem;
  font-weight: 600;
  padding: 12px 24px;
}

.success-page,
.error-page,
.error404-page {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.success-page .page-title,
.success-page .description,
.error-page .page-title,
.error-page .description,
.error404-page .page-title,
.error404-page .description,
.error404-page .sub-description {
  background: -webkit-linear-gradient(var(--primary), var(--secondary-dark));
  color: var(--primary) !important;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.success-page .page-title,
.error-page .page-title {
  font-size: 2.5em !important;
  font-weight: 700;
  letter-spacing: 2px !important;
}

.error404-page .page-title {
  font-size: 12rem;
  font-weight: 700;
  letter-spacing: 12px;
  line-height: 1;
}

.success-page .description,
.error-page .description,
.error404-page .description,
.error404-page .sub-description {
  font-size: 1.25em !important;
  font-weight: 500;
  letter-spacing: 1px !important;
}

.error404-page .description {
  font-size: 2rem !important;
  margin-bottom: 16px;
}

.success-page .btn-dashboard,
.error-page .btn-dashboard {
  background-color: transparent !important;
  border: 2px solid var(--secondary);
  border-radius: 10px;
  color: var(--primary) !important;
  font-size: 1.125rem;
  font-weight: 600;
  padding: 10px 45px;
  margin: 20px 0px;
  text-align: center;
}

.success-page .btn-dashboard:hover,
.error-page .btn-dashboard:hover {
  border-color: #9f7e57 !important;
  transition: all 0.25s ease-in;
  zoom: 1.01;
}

.login-page {
  background-image: url("../img/Login_BG.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.login-container {
  position: relative;
  height: 100vh;
  width: 100vw;
}

.login-container .login-box {
  background-color: var(--dark);
  border-radius: 10px;
  margin: 0 auto;
  min-width: 290px;
  max-width: 425px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.login-container .login-header {
  padding: 30px 30px 24px;
  border-bottom: 1px solid var(--secondary);
}

.login-box .login-img {
  max-height: 32px;
  margin-top: -8px;
  margin-right: 10px;
}

.login-box .login-title {
  font-size: 1rem;
  display: inline-block;
  margin: 0px;
}

.login-box .login-body {
  padding: 30px 30px 45px;
}

.login-box .form-text {
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0px;
}

.dashboard-card {
  border-radius: 5px;
  margin: 0px -8px;
}

.dashboard-card .card-header {
  background-color: transparent;
  border-bottom: 1px solid var(--secondary);
  font-size: 1rem;
  font-weight: 600;
  padding: 10px;
}

.dashboard-card .card-header .card-header-img {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.dashboard-card .card-body {
  padding: 15px 8px;
  text-align: center;
}

.dashboard-card .label {
  font-size: 0.875rem;
  font-weight: 600;
  margin: 8px 0px;
}

.dashboard-card .read-only-info {
  background-color: var(--darker);
  border-radius: 4px;
  color: var(--gray);
  margin: 8px 0px;
  padding: 8px 2px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dashboard-card table {
  background-color: var(--darker);
  border-radius: 4px;
  color: var(--gray) !important;
  margin: 0px 0px;
}

.dashboard-card .table th,
.dashboard-card .table td {
  font-size: 0.75rem;
  letter-spacing: -0.5px;
  padding: 6px 2px;
}

.dashboard-card textarea,
.dashboard-card .btn-dashboard,
.dashboard-card .input-dashboard,
.dashboard-card .select-dashboard .react-select__control {
  background-color: var(--dark-blue) !important;
  border-color: var(--darker) !important;
  color: var(--gray) !important;
  font-size: 0.875rem;
  font-weight: 500;
  padding: 4px 8px;
  text-align: center;
  box-shadow: none !important;
  outline: none !important;
}

.dashboard-card .btn-dashboard {
  font-size: 0.75rem;
  padding: 8px 4px;
}

.dashboard-card .btn-dashboard.btn-action {
  font-size: 1rem;
  padding: 6px 8px;
}

.dashboard-card textarea {
  border-radius: 4px;
  letter-spacing: 0px;
  line-height: 1.45;
  padding: 8px 4px;
  resize: none;
  width: 100%;
}

.dashboard-card textarea#resiProxyList {
  min-height: 646px;
}

.dashboard-card textarea#dcProxyList {
  min-height: 220px;
}

.dashboard-card .btn-dashboard.btn-dark {
  background-color: #131617 !important;
}

.dashboard-card .input-dashboard {
  height: auto;
  padding: 8px 8px;
}

.dashboard-card .quick-create .select-dashboard#resiPremSites {
  width: 94%;
  margin: 4px auto;
}

.dashboard-card .proxy-generator .select-dashboard {
  display: inline-block;
  margin: 5px;
  width: 45%;
}

.dashboard-card .select-dashboard .react-select__control {
  padding: 0px 0px;
  border-color: var(--gray);
}

.dashboard-card .select-dashboard .react-select__value-container {
  padding: 0px 2px;
}

.dashboard-card .select-dashboard .react-select__single-value {
  color: var(--gray);
}

.dashboard-card .select-dashboard .react-select__indicator-separator {
  display: none;
}

.dashboard-card .select-dashboard .react-select__dropdown-indicator {
  padding: 4px 4px;
}

.dashboard-card .select-dashboard .react-select__menu {
  background-color: var(--dark);
  border: 1px solid var(--darker);
  color: var(--gray);
  font-size: 0.75rem;
  padding: 0px;
  margin: 0px;
  text-align: left;
  max-height: 154px;
}

.dashboard-card .select-dashboard .react-select__menu-list {
  max-height: 154px;
  margin: 0px;
  padding: 2px 0px;
}

.dashboard-card .select-dashboard .react-select__option {
  background-color: var(--dark);
  color: var(--gray);
  font-size: 0.75rem;
  font-weight: 600;
  padding: 6px 4px;
}

/* .dashboard-card
  .select-dashboard
  .react-select__option.react-select__option--is-selected, */
.dashboard-card
  .select-dashboard
  .react-select__option.react-select__option--is-focused {
  background-color: var(--darker);
}

.dashboard-card.generate-card .btn-dashboard,
.dashboard-card.account-card .btn-dashboard {
  font-size: 0.75rem;
  margin: 5px;
  width: 45%;
}

.orderHistory-table-container {
  background-color: var(--darker);
  border-radius: 4px;
  color: var(--gray) !important;
  font-size: 0.75rem;
  max-height: 20rem;
  overflow-y: scroll;
}

/* .dashboard-card .orderHistory-table-container::-webkit-scrollbar {
  width: 4px;
}

.dashboard-card .orderHistory-table-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 4px var(--darker);
  border-radius: 2px;
}

.dashboard-card .orderHistory-table-container::-webkit-scrollbar-thumb {
  background: var(--dark);
  border-radius: 2px;
} */

.ipaddr-info {
  font-size: 0.625rem;
  padding: 2px 0px;
  text-align: left;
}

.dashboard-card.generate-card,
.dashboard-card.usage-card,
.dashboard-card.datacenter-card {
  min-height: 475px;
}

.dashboard-card.account-card,
.dashboard-card.control-card {
  min-height: 278px;
}

.tooltip-inner {
  max-width: 275px;
  padding: 8px 8px;
  color: var(--primary);
  background-color: #000;
  text-align: left;
  letter-spacing: 0.5px;
  line-height: 1.5;
  font-size: 0.75rem;
}

@media screen and (max-width: 991px) {
  #home .title {
    font-size: 1.875rem;
    line-height: 1.35;
    padding: 10px 0px;
  }

  #home .info {
    font-size: 1rem;
    line-height: 1.35;
    padding: 10px 0px;
    max-width: 320px;
  }

  #home .btn-goto-purchase {
    font-size: 1rem;
    padding: 10px 30px;
    margin: 10px 0px;
  }

  .card.plan-card {
    padding: 15px 15px;
  }

  .plan-card .plan-card-features li {
    font-size: 1rem;
    text-indent: -15px;
  }

  .socials-container .btn-socials {
    padding: 10px 16px;
  }

  .login-box .form-text {
    font-size: 0.625rem;
    letter-spacing: -0.5px;
    margin-top: 8px !important;
  }

  .dashboard-card .proxy-generator .select-dashboard {
    width: 100% !important;
  }
}

@media screen and (max-width: 767px) {
  .page-title {
    font-size: 1.5rem;
  }

  #features,
  #faq,
  #socials {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  #home img {
    display: none;
  }

  .plan-section-group {
    display: table;
    float: none !important;
    margin-top: 16px !important;
  }

  .plan-card .card-header,
  .plan-card .card-body {
    padding: 10px 10px;
  }

  .plan-card .plan-card-title {
    font-size: 1.25rem;
  }

  .plan-card .plan-card-price {
    font-size: 1.875rem;
  }

  .plan-card .plan-card-features {
    padding: 0px 10px;
  }

  .plan-card .plan-card-features li {
    font-size: 0.875rem;
    padding: 8px 0px;
  }

  .features-container .main-card {
    padding: 30px 15px;
  }

  .feature-card {
    padding: 5px 5px;
  }

  .feature-card .feature-img {
    width: 45px;
    margin-bottom: 5px;
  }

  .feature-card .feature-title {
    font-size: 1rem;
  }

  .feature-card .feature-description {
    padding: 5px 5px;
  }

  .faq-card .card-header {
    font-size: 1rem;
    padding: 0px 32px 0px 20px;
  }

  .socials-container {
    padding: 45px 15px;
  }

  .socials-container .btn-socials {
    width: 100%;
    margin: 8px 0px !important;
  }

  .success-page .page-title,
  .error-page .page-title {
    font-size: 1.625em !important;
    letter-spacing: 1px !important;
  }

  .error404-page .page-title {
    font-size: 5rem;
    letter-spacing: 5px;
    line-height: 1;
    margin-bottom: 0px;
  }

  .success-page .description,
  .error-page .description,
  .error404-page .description,
  .error404-page .sub-description {
    font-size: 1rem !important;
    font-weight: 500;
  }

  .error404-page .description {
    font-size: 1rem !important;
    margin-bottom: 10px;
  }

  .success-page .btn-dashboard,
  .error-page .btn-dashboard {
    font-size: 1rem;
    padding: 10px 20px;
    margin: 10px 0px;
  }
}

.animate__generate {
  -webkit-animation-name: generate;
  animation-name: generate;
}

.animate__proxylist {
  -webkit-animation-name: proxylist;
  animation-name: proxylist;
}

.animate__datacenter {
  -webkit-animation-name: datacenter;
  animation-name: datacenter;
}

.animate__usage {
  -webkit-animation-name: usage;
  animation-name: usage;
}

.animate__control {
  -webkit-animation-name: control;
  animation-name: control;
}

.animate__account {
  -webkit-animation-name: account;
  animation-name: account;
}

@keyframes generate {
  0% {
    top: 325%;
    left: 325%;
    transform: translate(-325%, -325%) scale(0);
  }

  50% {
    transform: scale(0.5);
  }

  100% {
    transform: translate(-90%, -55%) rotate(-20deg) scale(1);
  }
}

@keyframes proxylist {
  0% {
    top: 325%;
    left: 325%;
    transform: translate(-325%, -325%) scale(0);
  }

  50% {
    transform: scale(0.5);
  }

  100% {
    transform: translate(-30%, -50%) rotate(-20deg) scale(1);
  }
}

@keyframes datacenter {
  0% {
    top: 325%;
    left: 325%;
    transform: translate(-325%, -325%) scale(0);
  }

  50% {
    transform: scale(0.5);
  }

  100% {
    transform: translate(-95%, -50%) rotate(-20deg) scale(1);
  }
}

@keyframes usage {
  0% {
    top: 325%;
    left: 325%;
    transform: translate(-325%, -325%) scale(0);
  }

  50% {
    transform: scale(0.5);
  }

  100% {
    transform: translate(-60%, -30%) rotate(-20deg) scale(1);
  }
}

@keyframes control {
  0% {
    top: 325%;
    left: 325%;
    transform: translate(-325%, -325%) scale(0);
  }

  50% {
    transform: scale(0.5);
  }

  100% {
    transform: translate(-60%, 0%) rotate(-20deg) scale(1);
  }
}

@keyframes account {
  0% {
    top: 325%;
    left: 325%;
    transform: translate(-325%, -325%) scale(0);
  }

  50% {
    transform: scale(0.5);
  }

  100% {
    transform: translate(-55%, 70%) rotate(-20deg) scale(1);
  }
}
